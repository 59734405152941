<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="true"></loading>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';

export default {
  components: {
    Loading
  },
  data() {
    return {
      isLoading: false,
    }
  },
  mounted() {
    let info = {
      action: "showReport",
      id: this.$route.query.id
    };
    this.$router.push({
      path: '/marketReportResult',
      name: 'MarketReportResult',
      query: info,
      params: info
    });
  },
  methods: {}
}
</script>
